<template>
  <a-config-provider :theme="defaultTheme"
                     hash-priority="high"
                     :transformers="[legacyLogicalPropertiesTransformer]">
    <RouterView>

    </RouterView>
  </a-config-provider>
</template>

<script setup>
import {legacyLogicalPropertiesTransformer, message, theme} from "ant-design-vue";
import {onMounted, ref} from "vue";
import {magics} from "@/libs/data";

message.config({
  top: `50px`,
  duration: 2,
  maxCount: 1,
  rtl: true,
});
const defaultTheme = ref({})
onMounted(() => {

  if (localStorage.getItem('theme')) {
    setLightTheme()
  } else {
    setDarkTheme()
  }
})

const setLightTheme = () => {
  document.body.classList.add('light')
  defaultTheme.value = {
    algorithm: theme.defaultAlgorithm,
    token: {
      colorBgBase: '#ebebeb',
      colorTextBase: '#1b1b1b',
      lineType: 'dashed',
      colorSplit: '#bbb',
    }
  }
}

const setDarkTheme = () => {
  document.body.classList.remove('light')
  defaultTheme.value = {
    algorithm: theme.darkAlgorithm,
    token: {
      colorBgBase: 'black',
      colorTextBase: '#a38b63',
      colorPrimary: 'red'
    }
  }
}

window.setTheme = (theme) => {
  switch (theme) {
    case 'dark':
      setDarkTheme()
      break
    case 'light':
      setLightTheme()
      break
  }
}

window.magics = magics

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--default-text-color);
  height: 100%;
  height: 100vh;
  height: 100svh;
  position: relative;
}

</style>
